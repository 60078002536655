<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>
<script>
import * as echarts from 'echarts'
import "echarts-gl";
import { debounce } from '@/utils'
import { markRaw } from 'vue'
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    dataY: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    dataX: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    units:{
      type:String
    },
  },

  data() {
    return {
      chart:null
    }
  },
  watch:{
    'dataY':{
      handler() {
         this.refreshData()
      },
      deep:true,
    },
  },
  mounted() {
    this.refreshData()
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 500)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeUnmount() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
        this.chart = markRaw(echarts.init(this.$el))
        this.setOption()
    },
    setOption(){
      this.chart.setOption({
          grid: {
              left: "10%",
              top: "10%",
              bottom: '10%',
              right: '3%',
          },
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              },
          },
          xAxis: {
              type: "category",
              data:this.dataX,
              splitLine: { show: false },
              axisLine: {
                  show: true,
              },
              axisLabel: {
                  show: true,
                  color: "#fff",
                  fontSize: 12,
              },
              axisTick: {
                  show: false,
              },
          },
          yAxis:{
              type: "value",
              splitLine: { show: false },
              axisLine: {
                  show: true,
              },
              axisLabel: {
                  show: true,
        
                  color: "#fff",
                  fontSize: 12,
              },
              axisTick: {
                  show: false,
              },
          },
          series: [
              {
                  name: "报警数量",
                  type: "bar",
                  barWidth: 30,
                  data: this.dataY,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#FF720B'
                    },
                    {
                        offset: 1,
                        color: '#E23D3D'
                    }
                    ]),
                  },
                  emphasis: {
                    itemStyle:{
                      opacity: 1,
                    }
                  },
              },
          ],
      })
    },
    refreshData(){
        //刷新数据
        if(this.chart){
          this.setOption();  
        }else{
          this.initChart()
        }  
    }
  }
}

</script>